<!-- 应用场景审批 -->
<template>
  <div v-loading="loading" class="container">
    <div class="header">
      <span class="header-tit">{{ routerName=='edit'? '编辑应用场景审批工单类型':'新建应用场景审批工单类型'}}</span>
      <div class="hebtns">
        <el-button
          @click="
            () => {
              $router.go(-1);
            }
          "
          class="common-screen-btn btn"
          >返回</el-button
        >
        <el-button
          @click=" () => { routerName=='edit'? editSave():saveWork() } "
          type="primary"
          class="common-screen-btn btn"
          >保存</el-button
        >
      </div>
    </div>
    <div class="line"></div>
    <div class="body">
      <div class="calname">
        <span>类型名称</span>
        <el-input
          v-model="params.workTypeName"
          class="common-screen-input"
          placeholder="请输入"
          maxlength="16"
          show-word-limit
        ></el-input>
      </div>
      <div class="calname" style="display: flex;">
        <span>类型描述</span>
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="params.typeDescribe"
          maxlength="200"
          show-word-limit
          class="common-screen-input"
        >
        </el-input>
      </div>
      <div>
        <span>关联信息</span>
        <el-radio v-model="isExpenditure" class="common-screen-input" :label="2">关联调价</el-radio>
      </div>
    </div>

    <!-- 关联信息 -->
    <div v-if="isExpenditure" style="border-bottom: 1px solid #eee;">
      <span class="set relate">关联信息</span>

      <el-form ref="ruleFormEdit" label-width="97px" class="demo-ruleForm">
        <el-form-item label="订单编号">
          <el-input placeholder="系统填充" :disabled="true"> </el-input>
        </el-form-item>
        <el-form-item label="订单应收金额">
          <el-input
            class="common-input-YUAN"
            placeholder="系统填充"
            :disabled="true"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="改价类型" class="gj">
          <el-radio-group disabled>
            <el-radio :label="0" class="reRadio" style="margin-bottom: 16px;">加班费</el-radio>
            <el-radio :label="1" class="reRadio">咨询量差价</el-radio>
            <el-radio :label="2" class="reRadio">增减坐席/人力</el-radio>
            <el-radio :label="3" class="reRadio">服务扣款</el-radio>
            <el-radio :label="5" class="reRadio">服务对接群</el-radio>
            <el-radio :label="4" class="reRadio">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="overtime-container">
          <el-form-item label="店铺" prop="shopIds">
            <el-select
              v-model="val"
              class="dialog-input"
              placeholder="手动选择"
              multiple
              filterable
              disabled
            >
              <el-option label="区域一" value="shanghai"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间段" prop="datePicker">
            <el-date-picker
              class="dialog-input"
              type="date"
              placeholder="手动选择"
              disabled
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="子账号数量">
            <el-input
              disabled
              class="dialog-input"
              type="number"
              maxLength="50"
              placeholder="手动输入"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="调整金额" prop="changeMoneny">
          <el-input
            class="common-input-YUAN width-35"
            type="number"
            placeholder="手动输入"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="改价结果" prop="changeMoneny">
          <el-input
            class="common-input-YUAN width-35"
            type="number"
            placeholder="系统计算"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="改价说明" class="abs">
          <el-input
            disabled
            class="dialog-input"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            placeholder="手动输入"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <!-- 流程 -->
    <div class="pro-container">
      <span class="set">流程设置</span>
      <div class="process">
          <el-steps direction="vertical" align-center>
            <el-step title="审批工单"></el-step>
            <!-- 数组遍历 -->
            <el-step v-for="(item,i) in params.workOrderFlowDTOList" :key="i" class="everystep">
              <template #icon>
                {{2+i}}
              </template>
              <template #title>
                <div class="box">
                  <span class="black">审批人</span>
                  <div class="person">
                    <!-- 选中数组 -->
                    <div>
                      <span v-for="(item3,ii) in hanObj[i]" :key=ii class="ever">{{item3.name}}</span>
                    </div>
                    <el-button @click="() => { handlerVisible = true;armNum = i }" type="text" class="btn">选择</el-button>
                  </div>
                </div>
                <div class="btn-group">
                  <el-button @click="() => { sortUp(item,i) }" icon="el-icon-top" plain>上移</el-button>
                  <el-button @click="() => { sortDown(item,i) }" icon="el-icon-bottom" plain>下移</el-button>
                  <el-button v-if="i != 0" @click="() => { deleteStep(item,i) }" type="primary" icon="el-icon-delete" class="delbtn">删除</el-button>
                </div>
              </template>
            </el-step>
            <!-- 数组遍历 end-->
            <!-- 新增按钮 -->
            <el-step class="add">
              <template #icon>
                <el-button
                  @click="
                    () => {
                      addList();
                    }
                  "
                  icon="el-icon-circle-plus-outline"
                  circle
                ></el-button>
              </template>
            </el-step>
            <el-step>
              <template #icon>
                {{ 1 + params.workOrderFlowDTOList.length + 1 }}
              </template>
              <template #title>
                <div class="box">
                  <span class="black">抄送人</span>
                  <div class="person">
                    <!-- 选中数组 -->
                    <div>
                      <span v-for="(item,i) in sendList" :key=i class="ever">{{item.name}}</span>
                    </div>
                    <el-button @click="() => { handlerVisible = true;type = 'one' }" type="text" class="btn">选择</el-button>
                  </div>
                </div>
              </template>
            </el-step>
            <el-step title="关闭工单">
              <template #icon>
                {{ 1 + params.workOrderFlowDTOList.length + 2 }}
              </template>
            </el-step>
          </el-steps>
      </div>
    </div>

    <!-- 设置处理人弹 -->
    <el-dialog 
      v-dialogDrag 
      title="设置处理人" 
      :append-to-body="true" 
      :visible.sync="handlerVisible" 
      width="630px" 
      :before-close="() => { handlerVisible = false;radio = 1;type = ''; }" 
      :close-on-click-modal="false">
      <div>
        <el-radio-group v-model="radio">
          <el-radio :label="1">角色</el-radio>
          <el-radio :label="2">发起人自选</el-radio>
          <el-radio v-if="isUseCompany != '1'" :label="3">指定人员</el-radio>
        </el-radio-group>
        <br/>
        <br/>
        <el-button v-if="radio == 1" @click="() => { chooseRoles() }" size="mini" class="chrole" icon="el-icon-plus">选择角色</el-button>
        <el-button
          v-if="radio == 3"
          @click="
            () => {
              choosePeople();
            }
          "
          size="mini"
          class="chrole"
          icon="el-icon-plus"
          >选择人员</el-button
        >
      </div>
      <span v-if="radio==2" slot="footer" class="dialog-footer">
          <el-button class="common-screen-btn" @click="() => { handlerVisible = false;radio = 1;type = ''; }">取 消</el-button>
          <el-button class="common-screen-btn" type="primary" @click="() => { colletRoleId(); handlerVisible = false;radio = 1;}">确 定</el-button>
      </span>
    </el-dialog>

    <choose-role ref="choRole" :isPeople="isPeople" :oneRole="oneRole" :personVisible="roleVisible" :showRoleId="showRoleId" @roleClose="roleClose" @colletRoleId="colletRoleId"></choose-role>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { insertWorkOrder,selectWorkOrder,getTemplateRole, updateWorkOrder } from "../../service/workOrder.js";
import { Config } from "../../utils/index.js";
import chooseRole from "./children/chooseRole.vue"
import { commonAllRole } from "../../service/common.js"

export default {
  name: "sencePage",
  components: { chooseRole },
  props: {},
  data() {
    return {
      Config,
      loading: false,
      params: {
        workTypeName: '',
        typeDescribe: "",
        workTypeId: 3,
        workOrderFlowDTOList:[
          {approveType: '',approveId: '',sort: ''}
        ]
      },
      id: this.$route.query.id,
      routerName: this.$route.query.name,
      handlerVisible: false, //设置处理人弹
      roleVisible: false,//选择角色
      radio: 1,
      ///////流程/////////////
      armNum: null,//判断哪个流程选择人员
      hanObj: {},//用于展示处理人遍历数据
      //////////////
      type: '', //抄送人||负责人
      sendList: [], //抄送人
      roleDataList: [],//获取角色列表
      roleInfoList: [],//角色id和name
      showRoleId:"", //回显到选择角色
      oneRole: '',
      isPeople: false,
      val: "",
      isExpenditure: 2, // 2: 关联调价
      isUseCompany: ''
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.commonAllRole()
    if(this.$route.query.name == 'edit'){
      this.params.workOrderFlowDTOList = []
      this.getDetails()
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    async commonAllRole () { // 角色列表
      // 获取全部角色
      this.roleDataList = []
      this.roleInfoList = []
      let { data } = await commonAllRole({companyId: -1})
      data.forEach(element => {
        this.roleDataList.push(element.id)
        this.roleInfoList.push({id: element.id,name: element.name})
      });

    },
    // 保存按钮
    async saveWork() {
      let params = JSON.parse(JSON.stringify(this.params));
      // 提醒
      if (params.workOrderFlowDTOList&&params.workOrderFlowDTOList.length){
        let isNull = false
        params.workOrderFlowDTOList.forEach(ele => {
          if(!ele.approveType){
            isNull = true
          }
        })
        if (isNull) {
            return this.$message.warning("请先进行设置审批人再保存工单类型")
        }
      }
      if (!params.sendType){
        return this.$message.warning("请选择抄送人")
      }

      if(this.isExpenditure){
        params.isExpenditure = this.isExpenditure
      }

      const res = await insertWorkOrder(params);
      if(res.code == 200){
        this.$message.success("操作成功")
        this.$router.push({path: '/work-order/setting',query:{num: 'third'}})
      }
    },
    addList(){// +按钮
      this.params.workOrderFlowDTOList.push({approveType: '',approveId: '',sort: ''})
    },
    roleClose(){
      this.handlerVisible = false
      this.roleVisible = false
      this.isPeople = false;
      this.oneRole =""
      this.type = ""
    },
    colletRoleId(val){
        let roleList = [];
        if(val&&val.length){
          val.forEach(item => {
            roleList.push(item.id)
          })
        }
        if(this.type == 'one'){ //抄送人
          if(this.radio == 1){
            this.sendList = val
            this.params.sendId = roleList.join(',')
            this.params.sendType = 1
          } else if(this.radio == 3){ //指定人员
            this.sendList = val;
            this.params.sendId = roleList.join(",");
            this.params.sendType = 2;
          } else{
            this.params.sendId = ""
            this.sendList = [{name: '发起人自选'}];
            this.params.sendType = 3
          }
        }else{ // 流程
          if(this.radio == 1){
            this.params.workOrderFlowDTOList[this.armNum].approveId = roleList.join(',')//参数
            this.hanObj[this.armNum] = val //用于处理人遍历数据
            this.params.workOrderFlowDTOList[this.armNum].approveType = 1
            this.params.workOrderFlowDTOList[this.armNum].sort = this.armNum + 1
          } else if(this.radio == 3){ //指定人员
            this.params.workOrderFlowDTOList[this.armNum].approveId = roleList.join(',')//参数
            this.hanObj[this.armNum] = val //用于处理人遍历数据
            this.params.workOrderFlowDTOList[this.armNum].approveType = 2
            this.params.workOrderFlowDTOList[this.armNum].sort = this.armNum + 1
          } else{
            this.params.workOrderFlowDTOList[this.armNum].approveId = ""
            this.hanObj[this.armNum] = [{name: '发起人自选'}]; //用于处理人遍历数据
            this.params.workOrderFlowDTOList[this.armNum].approveType = 3
            this.params.workOrderFlowDTOList[this.armNum].sort = this.armNum + 1
          }
          
        }
        this.type = ""
    },
    chooseRoles(){
      if(this.type == 'one'){ //抄送人
        this.roleVisible = true;
        this.oneRole = 'one'
        this.showRoleId = this.params.sendId
        this.$refs.choRole.showRole(this.showRoleId,"one")
      }else{
        this.roleVisible = true;
        this.showRoleId = this.params.workOrderFlowDTOList[this.armNum].approveId
        this.$refs.choRole.showRole(this.showRoleId)
      }
    },
    choosePeople(){ //选择指定人员
        this.roleVisible = true;
        this.isPeople = true
      if(this.type == 'one'){
        if(this.params.sendType=='1'){
          this.showRoleId = ""
        }else {
          this.showRoleId = this.params.sendId;
        }
        this.$refs.choRole.showRole(this.showRoleId,"",JSON.stringify(this.sendList));
      } else {
        if(this.params.workOrderFlowDTOList[this.armNum].approveType=='1'){ //角色 ==> 指定人
          this.showRoleId = ""
        }else {
          this.showRoleId = this.params.workOrderFlowDTOList[this.armNum].approveId;
        }

        this.$refs.choRole.showRole(this.showRoleId,"",JSON.stringify(this.hanObj[this.armNum]));
      }
    },
    async getTemplateRole(ids,name,index) { // 获取模板角色列表
      let { data } = await getTemplateRole({ids})
      if(name == 'send'){
        this.sendList = data
      }
      if(name == 'list'){
        // this.hanObj[index] = data
        this.$set(this.hanObj,index,data)
      }
    },
    async getDetails(){
      this.loading = true
      const { data } = await selectWorkOrder({id: this.$route.query.id})

      this.isUseCompany = data.isUseCompany

      this.params.workTypeName = data.workTypeName
      this.params.typeDescribe = data.typeDescribe
      this.params.workTypeId = data.workTypeId
      this.params.sendType = data.sendType
      this.params.sendId = data.sendId
      this.isExpenditure = data.isExpenditure

      
      if (data.workOrderFlowDTOList&&data.workOrderFlowDTOList.length){ //流程
        data.workOrderFlowDTOList.forEach((element,index) => {
          if(element.approveType == 2){ //指定人
            // 1. 处理人
            let han = []
            let hanIdList = []
            let hanNameList= []
            hanIdList = element.approveId.split(",")
            hanNameList = element.approveName.split(",")
            if(hanIdList.length){
              hanIdList.forEach((item,index)=>{
                han.push({id: item,name: hanNameList[index]})
              })
            }
            this.hanObj[index] = han
          } else if(element.approveType == 3) { //发起人自选
            let han = []
            han.push({name: '发起人自选'})
            this.hanObj[index] = han
          } else{
            this.getTemplateRole(element.approveId,'list',index)
          }
          
          this.params.workOrderFlowDTOList.push({approveType: element.approveType,approveId: element.approveId,sort: element.sort})
        });
      }
      if(data.sendType == 3){
            let send = []
            send.push({name: '发起人自选'})
            this.sendList = send
      } else if(data.sendType == 2) { //指定人
        // 2.抄送人
        if(data.sendId) {
          let sendIdList = []
          let sendNameList= []
          sendIdList = data.sendId.split(",")
          sendNameList = data.sendName.split(",")
          if(sendIdList.length){
            sendIdList.forEach((item,index)=>{
              this.sendList.push({id: item,name: sendNameList[index]})
            })
          }
        }
      } else{
        this.getTemplateRole(data.sendId,'send')
      }
      
      this.loading = false
    },
    sortUp(item,i){
      if(i == 0){
        this.$message.warning("已经是第一个啦")
      }else{
        this.params.workOrderFlowDTOList[i].sort = this.params.workOrderFlowDTOList[i-1].sort
        this.params.workOrderFlowDTOList[i-1].sort = this.params.workOrderFlowDTOList[i].sort
        let workList = this.params.workOrderFlowDTOList
        let temp = workList[i - 1] //上一条数据 
        this.$set(workList, i - 1, workList[i])
        this.$set(workList, i, temp)

        // 审批人位置改变
        let mine = this.hanObj[i]
        let your = this.hanObj[i-1]
        this.$set(this.hanObj,i,your)
        this.$set(this.hanObj,i-1,mine)
      }
    },
    // 下
    sortDown(item,i){
      let workList = this.params.workOrderFlowDTOList
      if (i == (workList.length - 1)) {
        this.$message({
          message: '已经是最后一个啦',
          type: 'warning'
        })
      } else {
        this.params.workOrderFlowDTOList[i].sort = this.params.workOrderFlowDTOList[i].sort + 1
        this.params.workOrderFlowDTOList[i+1].sort = this.params.workOrderFlowDTOList[i+1].sort - 1
        let temp = workList[i + 1]//下一条数据 
        this.$set(workList, i + 1, workList[i])
        this.$set(workList, i, temp)

        // 审批人位置改变
        let mine = this.hanObj[i]
        let your = this.hanObj[i+1]
        this.$set(this.hanObj,i,your)
        this.$set(this.hanObj,i+1,mine)
      }

    },
    deleteStep(item,i){
      let workList = this.params.workOrderFlowDTOList
      if(i||i==0){
        if(i != workList.length-1){
          workList[i+1].sort = workList[i+1].sort - 1
        }
      }
      
      workList = workList.filter((ele,index)=> {
        return index != i
      })
      // 审批人位置改变
        let mine = this.hanObj[i]
        let your = this.hanObj[i+1]
        this.$set(this.hanObj,i,your)
        this.$set(this.hanObj,i+1,mine)
      this.params.workOrderFlowDTOList = workList
    },
    // 编辑保存按钮
    async editSave(){
      let params = JSON.parse(JSON.stringify(this.params));
      // 提醒
      if(!params.workTypeName){
        return this.$message.warning("请输入类型名称")
      }
      if (!params.typeDescribe){
        return this.$message.warning("请输入类型描述")
      }
      if (params.workOrderFlowDTOList&&params.workOrderFlowDTOList.length){
        let isNull = false
        params.workOrderFlowDTOList.forEach(ele => {
          if(!ele.approveType){
            isNull = true
            // return this.$message.error("请选择处理人")
          }
        })
        if (isNull) {
          // setTimeout(()=>{
            return this.$message.warning("请先进行设置审批人再保存工单类型")
          // },10)
        }
      }
      if (!params.sendType){
        return this.$message.warning("请选择抄送人")
      }
      params.id = this.id
      const res = await updateWorkOrder(params)
      if(res.code == 200){
        this.$message.success("操作成功")
        this.$router.push({path: '/work-order/setting',query:{num: 'third'}})
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 16px 32px;
  text-align: left;
  .relate {
    display: inline-block;
    margin-top: 32px;
    margin-bottom: 25px;
  }
  .demo-ruleForm {
    position: relative;
    width: 502px;
    /deep/.gj {
      .el-form-item__label{
        line-height: 1;
      }
    }
    /deep/.abs {
      position: absolute;
      top: 0;
      right: -786px;
      .el-form-item__label{
        line-height: 1;
      }
      .dialog-input {
        width: 386px;
      }
    }
    
    .common-input-YUAN {
      width: 91%;
    }
    .reRadio{
        width: 90px;
    }
    .el-select {
      width: 100%;
    }
    .el-date-editor {
      width: 100%;
    }
    .overtime-container {
      margin-left: 85px;
      border-left: #1890ff solid 4px;
      .dialog-input {
        width: 261px !important;
      }
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-tit {
      font-size: 20px;
      color: #101010;
    }
    .btn {
      width: 101px;
    }
  }
  .line {
    position: absolute;
    top: 66px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #eee;
  }
  .body {
    text-align: left;
    margin-top: 44px;
    padding-bottom: 40px;
    border-bottom: 1px solid #eee;
    .calname {
      margin-bottom: 24px;
      /deep/.common-screen-input {
        width: 432px !important;
        .el-input__inner {
          padding-right: 60px;
        }
        .el-input__count-inner {
          font-size: 12px;
        }
      }
    }
  }
  .pro-container {
    .set {
      display: inline-block;
      margin: 24px 0;
    }
    /deep/.el-step {
      .el-step__main {
        margin-bottom: 43px;
        margin-left: 20px;
      }
    }
    /deep/ .everystep {
      .el-step__main {
        padding: 22px 10px;
        border: 1px solid #1890ff;
        border-radius: 4px;
      }
    }
    /deep/ .el-step__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333;
    }
    /deep/.add {
      .el-step__main {
        margin-bottom: 63px;
      }
    }
    /deep/ .el-step__icon {
      border: 3px solid #eff5ff;
      background-color: #1890ff;
      color: #fff;
      .el-step__icon-inner {
        color: #fff;
      }
    }
    /deep/ .is-plain {
      border: 1px solid #1890ff;
      i {
        color: #1890ff;
      }
      span {
        color: #1890ff;
      }
    }
    /deep/ .down {
      margin-right: 10px;
    }
    .process {
      border: 1px solid #d9d9d9;
      padding: 42px 28px;
      .box {
        display: inline-block;
        height: 44px;
        line-height: 1.73;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        overflow: hidden;
        .black {
          display: inline-block;
          width: 104px;
          text-align: center;
          box-sizing: border-box;
          padding: 10px 16px;
          background-color: #ebebeb;
          color: #333;
          font-size: 14px;
          font-weight: 400;
        }
        .white {
          display: inline-block;
          // padding: 10px 98px 10px 16px;
          color: #333;
          font-size: 14px;
          font-weight: 400;
          /deep/ .el-input__inner {
            height: 45px;
            border: none;
            padding-right: 60px;
          }
          /deep/.el-input__count-inner {
            font-size: 12px;
          }
        }
        .person {
          display: inline-block;
          padding: 0 12px;
          div {
            display: inline-block;
          }
          .ever {
            display: inline-block;
            padding: 4px 12px;
            color: #fff;
            background-color: #1890ff;
            border-radius: 4px;
            &:not(:first-child) {
              margin-left: 8px;
            }
          }
          .btn {
            display: inline-block;
            margin-left: 50px;
          }
        }
      }
      .des {
        margin-top: 24px;
        width: 706px;
        .desTit {
          box-sizing: border-box;
          padding: 10px 16px;
          border: 1px solid #d9d9d9;
          border-radius: 4px 4px 0 0;
          border-bottom: none;
          height: 40px;
          color: #333;
          background-color: #eaebea;
        }
        /deep/.el-textarea__inner {
          border-radius: 0 0 4px 4px;
        }
      }
      .handler {
        margin: 24px 0 0 0;
      }
      /deep/ .el-step__description {
        padding-right: 0;
      }
      .upload-container {
        margin-top: 24px;
        .fileList-container {
          margin-top: 12px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 350px;
            .fileItem-btn-Container {
              display: flex;
              align-items: center;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #dcdfe6;
            color: #666;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            // margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
      /deep/ .is-circle {
        position: relative;
        width: 24px;
        height: 24px;
        .el-icon-circle-plus-outline {
          position: absolute;
          top: -3px;
          left: -3px;
          font-size: 30px;
          color: #1890ff;
        }
      }
    }
  }
}
</style>
